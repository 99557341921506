<template>
  <div class="events">
      <h1> Events For Good</h1>
    <!--<img alt="Vue logo" src="../assets/logo.png" />-->
    <EventCard v-for="event in events" :key="event.id" :event="event"/>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue';
import EventService from '@/services/EventServices.js';

export default {
  name: 'EventList',
  components: {
    EventCard
  },
   data(){
    return{
      events: null
    }
  },
  created(){
   EventService.getEvents()
    .then(response =>{
      this.events = response.data
    })
    .catch(error=>{
      console.log("events error: ",error.data)
    })
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
