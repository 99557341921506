<template>
    <h1>Create an Event,{{ user.name }}</h1>
    <p>This event was created by {{ user.name }}</p>
    <p>There are {{ catLength}} categories</p>
    <p>{{getEventById(1)}}</p>
    <ul>
        <li v-for="categorie in categories" :key="categorie"  >{{categorie}}</li>
    </ul>
</template>

<script>
import {mapState , mapGetters} from 'vuex';
    export default {
        computed:{
            catLength(){
                return this.$store.getters.catLength;
            },
            ...mapGetters(['getEventById']),
            ...mapState(['user','categories'])
        }
    }
</script>

<style lang="css" scoped>
ul{
    list-style: none;
}
</style>