import { createStore } from "vuex";

export default createStore({
  state: {
    user:{id:'abcd123',name:'Ai' },
    categories:[
      'sustainability',
      'nature',
      'animal welfare',
      'housing',
      'education',
      'food',
      'community'
    ],
    todos:[
      {id:1,text:'...',done:true},
      {id:2,text:'...',done:false},
      {id:3,text:'...',done:true},
      {id:4,text:'...',done:false},
    ],
    events:[
      {id:1,text:'...',organizer:true},
      {id:2,text:'...',organizer:false},
      {id:3,text:'...',organizer:true},
      {id:4,text:'...',organizer:false},
    ]
  },
  mutations: {},
  actions: {},
  modules: {},
  getters:{
    catLength:state => {
      return state.categories.length;
    },
    doneTodos:state=>{
      return state.todos.filter(todo=>todo.done);
    },
    activeTodosCount:state=>{
      return state.todos.filter(todo=>!todo.done);
    },
    getEventById: state => id => {
      return state.events.find(event => event.id === id);
    }
  }
});
