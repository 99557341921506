import { createRouter, createWebHistory } from "vue-router";
import EventList from "../views/EventList.vue";
import EventDetails from '../views/EventDetails.vue';
import About from '../views/About.vue';
import EventCreate from '../views/EventCreate.vue';

const routes = [{
        path: "/",
        name: "EventList",
        component: EventList
    },
    {
        path: "/events/:id",
        name: "EventDetails",
        props: true,
        component: EventDetails
    },
    {
        path: "/about",
        name: "About",
        component: About
    },
    {
        path: "/create",
        name: "EventCreate",
        component: EventCreate
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;